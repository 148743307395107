import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { Link } from 'react-router-dom'
import Navmenu from './NavMenu';
import { FiPhoneCall } from 'react-icons/fi';
import { BsEnvelope } from 'react-icons/bs';
import MenuMobil from './MobilMenu/MenuMobil';


const Header_6 = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div>
            <div className="bg-2  pt-[0.5px] flex justify-center items-center">
                <div className="md:w-4/5 w-full mx-auto md:flex justify-center items-center p-1 rounded-b-3xl bg-2 mt-1 -mb-6  z-50">
                    <div className='w-full h-auto p-1 rounded-full bg-white rounded- flex items-center -mb-6 '>
                        <ul className="flex w-1/2 md:w-4/5 justify-center md:justify-start md:py-[5px] px-2">

                            {rpdata?.dbPrincipal?.phones?.[0].phone.length > 0 ? (
                                <>
                                    {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                                        return (

                                            <li key={index} className=" flex items-center">
                                                <a
                                                    href={`tel:+1${item.phone}`}
                                                    className=" flex items-center justify-center px-2 border-r-0 md:border-r-2 titleColorFt2"
                                                >
                                                    <span className='py-2 px-1 rounded-full border titleColorFt2 md:mr-0 mr-1'>
                                                        <FiPhoneCall className="md:text-[14px] text-[12px] mx-1" />
                                                    </span>
                                                    <span className="flex flex-col">
                                                        <span className="md:pl-3 font-bold md:text-[14px] text-[14px] text-black">
                                                            {item.name} {item.phone}
                                                        </span>

                                                    </span>
                                                </a>
                                            </li>

                                        );
                                    })}
                                </>
                            ) : null}
                            {rpdata?.dbPrincipal?.emails?.[0].email.length > 0 ? (
                                <>
                                    {rpdata?.dbPrincipal?.emails?.slice(0, 2).map((item, index) => {
                                        return (

                                            <li className="hidden lg:flex items-center">
                                                <a
                                                    href={`tel:+1${item.email}`}
                                                    className=" flex items-center px-2 border-r-0 md:border-r-2 titleColorFt2"
                                                >
                                                    <span className='py-2 px-1 rounded-full border md:mr-0 mr-1 titleColorFt2'>
                                                        <BsEnvelope className="md:text-[14px] text-[12px] mx-1" />
                                                    </span>
                                                    <span className="flex flex-col">
                                                        <span className="md:pl-3 font-bold md:text-[14px] text-[12px] text-black">
                                                            {item.name} {item.email}
                                                        </span>

                                                    </span>
                                                </a>
                                            </li>

                                        );
                                    })}
                                </>
                            ) : null}


                        </ul>
                        <ul className=" flex md:flex w-1/2 md:w-1/5 justify-end md:py-[5px] space-x-3 px-4 ">
                            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                return (
                                    <li key={index} className="space-x-1 border-r border-black pr-2 titleColorFt2 flex justify-center items-center">
                                        <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                            <i
                                                className={`fab fa-${item.icon}`}
                                                aria-hidden="true"
                                            />

                                        </a>
                                        <span className=" font-semibold text-sm text-black hidden md:block">{item.name}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            {
                rpdata?.simpleWidgets?.[3]?.activo ?
                    <div className='absolute right-[5%] left-[5%] z-50 pt-10  flex justify-center items-center'>

                        <div className='w-[70%] md:w-[20%] pr-3 md:pr-0'>
                            <Link to={'/'}>
                                <img
                                    src={`${rpdata?.dbPrincipal?.logo}`}
                                    alt='no found'
                                    className='w-full'
                                />
                            </Link>
                        </div>
                    </div>
                    :
                    <>
                        <div className='absolute right-[5%] left-[5%] z-50 pt-3  flex justify-center items-center mt-8 '>

                            <div className='w-[100%] pr-3 md:pr-0 flex flex-row md:flex-col items-center'>
                                <div className='w-[70%] md:w-[20%]'>
                                    <Link to={'/'}>
                                        <img
                                            src={`${rpdata?.dbPrincipal?.logo}`}
                                            alt='no found'
                                            className='w-full'
                                        />
                                    </Link>
                                </div>
                                <div className='w-auto pr-5 lg:block hidden'>
                                    <Navmenu /> 
                                </div>
                                <div className='w-auto lg:hidden block'>
                                    <MenuMobil />
                                </div>

                            </div>
                        </div>
                        {/* <div className='absolute right-[5%] left-[5%] z-50 pt-3  flex justify-center items-end h-auto mt-8 border'>
                            <div className='relative '>
                                <Navmenu />
                            </div>
                        </div> */}

                    </>

            }
        </div>
    )
}

export default Header_6